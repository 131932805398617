.home{
    background:url(../../images/HomeBg.webp) no-repeat;
    background-size: cover;
    background-position: center;
 }

 .home .content{
    width: 55rem;
    padding:2rem;
    margin-top: 130px;
 }
 
 .home .content h3{
   
    font-size: 6rem;
    text-transform: uppercase;
    color:var(--black);
 }
 .home .content span{

    text-transform: uppercase;
    font-size: 3rem;
    color:var(--blue);
 }

 .home .content p{
    line-height: 2;
    font-size: 2rem;
    color:var(--black);
    padding:1rem 0;
 }
 .home .content img{
    border: var(--border);
    margin-bottom: 2rem;
 }
 .home .content a{
    display: flex;  
    justify-content: center;
 }
 @media screen and (max-width: 550px) {
   .home .content{
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 40px;
   }
   .home .content h3{
      font-size: 3rem;
   }
 }